import { useEffect, useState } from 'react';
import '../styling.css'
import person from './logo.png'
import { v4 as uuidv4 } from 'uuid';

import { initializeApp } from '@firebase/app';
import { getDatabase } from 'firebase/database';
import { ref, set, get, update, remove, child } from "firebase/database";
import DotLoader from "react-spinners/DotLoader";
import Loaders from './Loaders.jsx'


function StartFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyD6Bk3_YGDxOk1lKSno_RCdx6gcTaDMhHg",
    authDomain: "fire-8def1.firebaseapp.com",
    databaseURL: "https://fire-8def1-default-rtdb.firebaseio.com",
    projectId: "fire-8def1",
    storageBucket: "fire-8def1.appspot.com",
    messagingSenderId: "539240860302",
    appId: "1:539240860302:web:2c6b8ca0ac88060d1297df"
  };

  const app = initializeApp(firebaseConfig);
  return getDatabase(app);
}

const database = StartFirebase()
const db = database;
const dbref = ref(db);

export default function Register() {

  let [color, setColor] = useState("#ffffff");
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('')
  const [phone, setPhone] = useState('');
  const [status, setStatus] = useState('');
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  function generateUUID() {
    return uuidv4();
  }


  useEffect(() => {

    return () => {

    };
  }, []);

  const submit = (e) => {

    setLoading(true);

    if (name && company && email && phone){
    update(ref(db, `absa/${generateUUID()}`), {
      name: name,
      email: email,
      company: company,
      phone: phone,
    }).then(() => {
      setStatus("Successful Registration");
      setFlag(true);
      setLoading(false);
    })
  }else{
    setStatus("Fill All Details");
      setFlag(true);
      setLoading(false);
  }

    setName('')
    setPhone('')
    setEmail('')
    setCompany('')

  };

  return (
    <div className='loginpages'>


      <form className='loginss' onSubmit={submit}>
        <div className='profimgs'>
          <img src={person} alt="" width="100" height="100" />
        </div>
        <div className='dso'>
          <p>REGISTRATION</p>
        </div>


        <div className='loginins'>
          <div className='inputs'>
            <label>Name</label>

            <input
              id="name"
              name="name"
              value={name}
              placeholder='Full Name '
              className="mt-1 block w-full"
              autoComplete="name"
              isFocused={true}
              onChange={(e) => setName(e.target.value)}
              required
            />


          </div>

          <div className='inputs'>
            <label>Company Name</label>

            <input

              type="text"
              name="email"
              value={company}
              placeholder='Company Name'
              className="mt-1 block w-full"
              autoComplete="email"
              onChange={(e) => setCompany(e.target.value)}
              required
            />


          </div>

          <div className='inputs'>
            <label>Email </label>

            <input
              id="email"
              type="email"
              name="email"
              value={email}
              placeholder='Email Address '
              className="mt-1 block w-full"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />


          </div>

          <div className='inputs'>
            <label>Phone Number</label>

            <input

              type="text"

              placeholder='Phone Number'
              value={phone}
              className="mt-1 block w-full"

              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>

        </div>
        {
          loading ? <div className="spin"> <DotLoader

            color={color}
            loading={loading}
            // cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          /></div> : <></>
        }

        <div className='logbtn' onClick={submit}>

          Register

        </div>

        {flag && <div className='staaa'>
          <p>{status}</p>
        </div>}




      </form>
    </div>
  );
}
