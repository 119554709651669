import React from 'react'


import { initializeApp } from '@firebase/app';
import { getDatabase } from 'firebase/database';
import { ref, set, get, update, remove, child } from "firebase/database";



function StartFirebase() {
    const firebaseConfig = {
        apiKey: "AIzaSyD6Bk3_YGDxOk1lKSno_RCdx6gcTaDMhHg",
        authDomain: "fire-8def1.firebaseapp.com",
        databaseURL: "https://fire-8def1-default-rtdb.firebaseio.com",
        projectId: "fire-8def1",
        storageBucket: "fire-8def1.appspot.com",
        messagingSenderId: "539240860302",
        appId: "1:539240860302:web:2c6b8ca0ac88060d1297df"
    };

    const app = initializeApp(firebaseConfig);
    return getDatabase(app);
}

const database = StartFirebase()
const db = database;
const dbref = ref(db);


export default function chat() {
  return (
    <div>
      
    </div>
  )
}
